import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

const splideFvElements = document.getElementsByClassName('splide__fv splide'); // right-image
const splideFvTextElements = document.getElementsByClassName('splide__fv-text splide');

if (splideFvElements.length) {
  let fvSlides = [];
  let fvTextSlides = [];

  // FVスライダーの初期化
  for (let i = 0; i < splideFvElements.length; i++) {
    let fvSlide = new Splide(splideFvElements[i], {
      // ここにオプションを設定
      type: 'fade',
      rewind: true,
      arrows: false,
      pagination: false,
      autoplay: true,
      speed: 800,
      pauseOnHover: false,
      interval: 5000,
      breakpoints: {
        1025: {
        }
      }
    }).mount();

    fvSlides.push(fvSlide);
  }

  // FVテキストスライダーの初期化
  for (let i = 0; i < splideFvTextElements.length; i++) {
    let fvTextSlide = new Splide(splideFvTextElements[i], {
      // ここにオプションを設定
      type: 'fade',
      rewind: true,
      arrows: false,
      pagination: false,
      autoplay: true,
      breakpoints: {
        1025: {
          interval: 12000
        }
      }
    }).mount();

    fvTextSlides.push(fvTextSlide);

    // 同期の設定
    fvSlides.forEach((fvSlide, index) => {
      fvSlide.on('move', (newIndex) => {
        fvTextSlides[index].go(newIndex);
      });
    });

    fvTextSlides.forEach((fvTextSlide, index) => {
      fvTextSlide.on('move', (newIndex) => {
        fvSlides[index].go(newIndex);
      });
    });
  }

}